import { EuiHealth } from "@elastic/eui";

export const StatusBadge = ({ value }) => {
  let color;
  let name;
  if (value === "live") {
    color = "success";
    name = "Live";
  } else if (value === "deprecated") {
    color = "subdued";
    name = "Deprecated";
  } else if (value === "planned") {
    color = "warning";
    name = "Planned";
  } else {
    color = "danger";
    name = "Unknown";
  }

  return (
    <EuiHealth textSize="inherit" color={color}>
      {name}
    </EuiHealth>
  );
};
