import "./App.css";
import "@elastic/eui/dist/eui_theme_amsterdam_dark.css";
import Page from "./components/Page";
import { RecoilRoot } from "recoil";

function App() {
  return (
    <RecoilRoot>
      <Page />
    </RecoilRoot>
  );
}

export default App;
