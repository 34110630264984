import { EuiBadge } from "@elastic/eui";

export const CategoryBadge = ({ value }) => {
  let color;
  let label = value.toUpperCase();
  if (value === "live") {
    color = "success";
  } else if (value === "deprecated") {
    color = "subdued";
  } else if (value === "unknown") {
    color = "warning";
  } else if (value === "") {
    color = "hallow";
    label = "N/A";
  }
  return (
    <EuiBadge color={color} isDisabled={value === ""}>
      {label}
    </EuiBadge>
  );
};
