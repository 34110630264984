import React, { Fragment, useState } from "react";
import { EuiInMemoryTable, EuiText } from "@elastic/eui";

import { Detail } from "./Detail";
import { CategoryBadge } from "./CategoryBadge";
import { StatusBadge } from "./StatusBadge";

const Table = ({ data }) => {
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 50 });
  const [flyoutData, setFlyoutData] = useState(undefined);
  const [query, setQuery] = useState("");

  // early exit with loading table if data is not here
  // TODO: need to implement proper loading and error state handling
  if (data === undefined) {
    return <EuiInMemoryTable items={[]} columns={[]} loading={true} />;
  }

  const columns = [
    {
      field: "name",
      name: "Name",
      sortable: true,
      width: "25em",
      render: (name) => {
        return (
          <EuiText size="s">
            <code>
              <b>{name}</b>
            </code>
          </EuiText>
        );
      },
    },
    {
      field: "category",
      name: "Category",
      sortable: true,
      width: "13em",
      render: (value) => {
        return <CategoryBadge value={value} />;
      },
    },
    {
      field: "status",
      name: "Status",
      sortable: true,
      width: "10em",
      render: (value) => {
        return <StatusBadge value={value} />;
      },
    },
    {
      field: "desc",
      name: "Description",
      truncateText: false,
    },
  ];

  const handleOnChange = ({ queryText, error }) => {
    if (!error) {
      setQuery(queryText);
    }
  };

  const categories = new Set(data.map((item) => item.category));

  const search = {
    query,
    onChange: handleOnChange,
    box: {
      schema: true,
      incremental: true,
    },
    filters: [
      {
        type: "field_value_selection",
        field: "status",
        name: "Status",
        multiSelect: "or",
        options: ["live", "deprecated", "planned", "unknown"].map((status) => {
          return {
            value: status,
            view: <StatusBadge value={status} />,
          };
        }),
      },
      {
        type: "field_value_selection",
        field: "category",
        name: "Category",
        multiSelect: "or",
        options: Array.from(categories.values()).map((category) => {
          return {
            value: category,
            view: <CategoryBadge grow={false} value={category} />,
          };
        }),
      },
    ],
  };

  const getRowProps = (item) => {
    return {
      onClick: () => {
        console.log(item);
        setFlyoutData(item);
      },
    };
  };

  const closeFlyout = () => setFlyoutData(undefined);

  return (
    <Fragment>
      <EuiInMemoryTable
        items={data}
        itemId="id"
        columns={columns}
        search={search}
        pagination={pagination}
        sorting={true}
        onTableChange={({ page: { index, size } }) =>
          setPagination({ pageIndex: index, pageSize: size })
        }
        rowProps={getRowProps}
      />

      {flyoutData !== undefined ? (
        <Detail data={flyoutData} closeClickHandler={closeFlyout} />
      ) : null}
    </Fragment>
  );
};

export default Table;
