import React from "react";
import { useRecoilValue, useRecoilValueLoadable } from "recoil";
import taxonomyList from "../recoil/taxonomyList/atom";
import currentTaxonomyMetaState from "../recoil/currentTaxonomy/metaState";
import {
  EuiPage,
  EuiPageContent,
  EuiPageContentBody,
  EuiPageHeader,
  EuiPageHeaderSection,
  EuiIcon,
  EuiTitle,
  EuiPageBody,
  EuiFlexGroup,
  EuiFlexItem,
  EuiButton,
  EuiButtonEmpty,
  EuiLoadingContent,
  EuiText,
} from "@elastic/eui";

import { formatDate } from "@elastic/eui/lib/services/format";

import Content from "./Content";

const TaxonomySelector = () => {
  const taxonomies = useRecoilValue(taxonomyList);
  const currentTaxonomyMeta = useRecoilValue(currentTaxonomyMetaState);

  const selectedOption = generateOption(currentTaxonomyMeta);
  const options = generateOptions(taxonomies);
  return (
    <EuiButton isLoading={false} iconSide="right" iconType="arrowDown">
      <EuiText size="s">
        <b>
          {selectedOption.label}
          {" ･ "}
        </b>
        {formatDate(selectedOption.createdAt)}
      </EuiText>
    </EuiButton>
  );
};

const generateOption = (taxonomyMetadata) => {
  return {
    value: taxonomyMetadata.id,
    label: taxonomyMetadata.title,
    createdAt: taxonomyMetadata.created_at,
  };
};

const generateOptions = (taxonomies) => taxonomies.map(generateOption);

const Page = () => {
  return (
    <EuiPage paddingSize="none">
      <EuiPageBody restrictWidth>
        <EuiPageHeader paddingSize="m">
          <EuiPageHeaderSection>
            <EuiFlexGroup
              alignItems="center"
              justifyContent="flexStart"
              gutterSize="m"
            >
              <EuiFlexItem grow={false}>
                <EuiIcon type="indexPatternApp" size="l" />
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiTitle size="s">
                  <h4>Taxonomy Explorer</h4>
                </EuiTitle>
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiButton iconType="arrowDown" iconSide="right">
                  <b>BytePlus</b>
                </EuiButton>
              </EuiFlexItem>
              <EuiFlexItem>
                <React.Suspense
                  fallback={<EuiButton isLoading={true}>Loading...</EuiButton>}
                >
                  <TaxonomySelector />
                </React.Suspense>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiPageHeaderSection>
          <EuiPageHeaderSection>
            <EuiButtonEmpty href="https://droom.cloudflareaccess.com/cdn-cgi/access/logout?returnTo=https://droom.cloudflareaccess.com/">
              Logout
            </EuiButtonEmpty>
          </EuiPageHeaderSection>
        </EuiPageHeader>
        <React.Suspense
          fallback={
            <EuiPageContent
              hasShadow={false}
              borderRadius="none"
              paddingSize="m"
            >
              <EuiLoadingContent lines={4} />
            </EuiPageContent>
          }
        >
          <EuiPageContent
            hasShadow={false}
            borderRadius="none"
            paddingSize="none"
          >
            <Content />
          </EuiPageContent>
        </React.Suspense>
      </EuiPageBody>
    </EuiPage>
  );
};

export default Page;
