import { selector } from "recoil";
import currentTaxonomyMetaState from "./metaState";

const currentTaxonomyDataState = selector({
  key: "currentTaxonomyDataState",
  get: async ({ get }) => {
    const meta = get(currentTaxonomyMetaState);
    if (meta === undefined) {
      return undefined;
    }

    const endpoint = process.env.REACT_APP_API_ENDPOINT || "https://taxonomy-api-dev.droom.workers.dev";
    const headers = { "Cache-Control": "no-cache" };
    const res = await fetch(`${endpoint}/api/taxonomies/${meta.id}`, { headers });
    const obj = await res.json();
    console.log(obj);
    return obj;
  },
});

export default currentTaxonomyDataState;
