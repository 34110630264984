import { selector } from "recoil";
import taxonomyList from "../taxonomyList/atom";
import selectedIdxState from "./selectedIdxState";

const currentTaxonomyMetaState = selector({
  key: "currentTaxonomyMetaState",
  get: ({ get }) => {
    const list = get(taxonomyList);
    if (list.length === 0) {
      return undefined;
    }
    const idx = get(selectedIdxState);
    return list[idx];
  },
});

export default currentTaxonomyMetaState;
