import React from "react";
import { atom, useRecoilState, useRecoilValue } from "recoil";
import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiTabs,
  EuiTab,
  EuiPanel,
} from "@elastic/eui";

import currentTaxonomyDataState from "../recoil/currentTaxonomy/dataState";

import Table from "./Table";

const tabs = [
  {
    id: "events",
    name: "Event",
    disabled: false,
  },
  {
    id: "user_props",
    name: "User Properties",
    disabled: false,
  },
];

export const selectedTabState = atom({
  key: "selectedTabState",
  default: "events",
});

const Content = () => {
  const [selectedTab, setSelectedTab] = useRecoilState(selectedTabState);
  const dataState = useRecoilValue(currentTaxonomyDataState);

  const onSelectedTabChanged = (id) => {
    setSelectedTab(id);
  };

  const renderTabs = () => {
    return tabs.map((tab, index) => (
      <EuiTab
        onClick={() => onSelectedTabChanged(tab.id)}
        isSelected={tab.id === selectedTab}
        disabled={tab.disabled}
        key={index}
      >
        {tab.name}
      </EuiTab>
    ));
  };

  let table;
  if (selectedTab === "events") {
    table = <Table data={dataState.data["events"]} />;
  } else {
    table = <Table data={dataState.data["user_props"]} />;
  }

  return (
    <EuiFlexGroup direction="column" gutterSize="none">
      <EuiFlexItem grow={false}>
        <EuiTabs display="medium">{renderTabs()}</EuiTabs>
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiPanel paddingSize="m">{table}</EuiPanel>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

export default Content;
