import { selector } from "recoil";

const taxonomyList = selector({
  key: "taxonomyList",
  get: async () => {
    const endpoint = process.env.REACT_APP_API_ENDPOINT || "https://taxonomy-api-dev.droom.workers.dev";
    const headers = { "Cache-Control": "no-cache" };
    const res = await fetch(`${endpoint}/api/taxonomies`, { headers });
    const obj = await res.json();
    return obj;
  },
});

export default taxonomyList;
