import React from "react";

import {
  EuiText,
  EuiTitle,
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutHeader,
  EuiCallOut,
  EuiSpacer,
  EuiBasicTable,
} from "@elastic/eui";

import { CategoryBadge } from "./CategoryBadge";

export const Detail = ({ data, closeClickHandler }) => {
  const columns = [
    {
      field: "name",
      name: "Name",
      sortable: true,
      width: "18em",
      render: (name) => {
        return (
          <EuiText size="s">
            <code>
              <b>{name}</b>
            </code>
          </EuiText>
        );
      },
    },
    {
      field: "desc",
      name: "Description",
    },
    {
      field: "example",
      name: "Example",
    },
  ];

  const table = data.props ? (
    <EuiBasicTable items={data.props} rowHeader="name" columns={columns} tableLayout="auto" />
  ) : undefined;

  const note =
    data.note !== "" ? (
      <EuiCallOut color="warning" title="주의 사항" iconType="alert">
        {data.note}
      </EuiCallOut>
    ) : undefined;

  return (
    <EuiFlyout ownFocus onClose={closeClickHandler} aria-labelledby="flyoutComplicatedTitle" paddingSize="l">
      <EuiFlyoutHeader hasBorder>
        <CategoryBadge value={data.category} />
        <EuiSpacer size="l" />
        <EuiTitle size="m">
          <h2 id="flyoutComplicatedTitle">
            <code>{data.name}</code>
          </h2>
        </EuiTitle>
        <EuiSpacer size="s" />
        <EuiText color="subdued">
          <p>{data.desc || "No Description"}</p>
        </EuiText>
      </EuiFlyoutHeader>
      <EuiFlyoutBody banner={note}>{table}</EuiFlyoutBody>
    </EuiFlyout>
  );
};
